footer {
    @extend .font-family--mali;
    color: $primary-color;
    background-color: $ts-yellow;

    hr {
        border: 1px solid white;
        background-color: white;
    }

    .site-contact {
        p {
            font-size: 0.95rem;
            font-weight: 600;
        }

        a {
            color: #535152;
            text-decoration: none;
        }

        .site-contact__icons {
            font-size: 2.25rem;
        }
    }

    .site-contact__social {
        h2 {
            font-size: 2rem;
        }

        .site-contact__icons {
            margin-top: -0.3em;
            font-size: 2.25rem;

            .fab {
                display: inline-block;
                background-color: $primary-color;
                color: $ts-yellow;
                width: 1em;
                height: 1em;
                padding: 0.25em 0.25em 0.25em 0.25em;
                -webkit-box-sizing: content-box;
                box-sizing: content-box;
                border-radius: 0.7em;
                font-size: 0.6em;
                line-height: 1.1em;
            }
        }
    }
}