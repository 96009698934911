// Variables

// sass-mq breakpoints
$mq-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
);

$primary-color: #535152;
$ts-yellow: #FFCC33;
$ts-purple: #7B55C6;
