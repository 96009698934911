.section--why-buy {
  .bg-purple {
    h2,
    p {
      color: white;
    }
  }

  .col-why-buy {
    position: relative;

    h1,
    p {
      position: relative;
      z-index: 2;
    }

    @include mq($from: lg) {
      padding-right: 90px;
    }
    @include mq($from: sm, $until: lg) {
      padding-right: 140px;
    }

    @include mq($until: sm) {
      overflow: hidden;
    }

    img {
      position: absolute;
      bottom: 0;
      height: calc(100% - 1.5em);
      max-height: 550px;
      right: 0;
      z-index: 1;

      @include mq($from: lg) {
        transform: translateX(33%);
      }
      @include mq($from: sm, $until: lg) {
        max-height: 350px;
      }
      @include mq($until: sm) {
        opacity: 0.3;
        transform: translateX(10%);
      }
    }
  }

  .col-bulk-sales {
    position: relative;
    background-color: #F2F2F2;

    @include mq($from: lg) {
      padding-left: 45px;
      padding-bottom: 45px;
    }
    @include mq($until: lg) {
      padding-bottom: 15px;
    }

    img {
      position: absolute;
      right: 0;

      @include mq($from: lg) {
        bottom: -93px;
        height: 250px;
      }

      @include mq($until: lg) {
        bottom: -63px;
        height: 175px;
      }
    }
  }
}
