.font-family--mali {
  font-family: "Mali", cursive;
}

.font-family--lilita-one {
  font-family: "Lilita One", cursive;
}

html {
  @include mq($from: lg) {
    font-size: 16px;
  }
  @include mq($until: lg) {
    font-size: 14px;
  }
}

h1 {
  @extend .font-family--lilita-one;
  font-size: 3.25rem;
  color: $primary-color;
  line-height: 1em;
}

h2 {
  @extend .font-family--lilita-one;
  font-size: 2.25rem;
  color: $primary-color;
}

p {
  @extend .font-family--mali;
  font-size: 1.7rem;
  color: $primary-color;
}

.btn  {
  @extend .font-family--mali;
}

