@media (max-width: 1170px) {
    .header__info-bar {
        position: relative;
        display: block;
        background-color: #535152;
        color: white;
        line-height: 1.2em;
        font-size: 0.9rem;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .header__info-bar .btn-lang {
        right: 15px;
    }
}

@media (max-width: 767px) {
    .header__info-bar .container {
        padding-right: 10%;
    }

    .header__info-bar {
        padding-top: 19px;
        padding-bottom: 15px;
    }

    .header__info-bar .btn-lang {
        padding: 10px 10px;
        top: 7px;
    }
}

@media (max-width: 533px) {
    .header__info-bar .container {
        padding-right: 17%;
    }
    .header__info-bar .btn-lang {
        top: 25%;
    }
}