.section--our-products {
  .product {
    position: relative;

    img {
      &.product__image {
        max-width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
      }

      &.product__label,
      &.product__stars {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      }

      &.product__label {
        top: 50%;
        transform: translateY(-50%);
        max-width: 64%;
      }

      &.product__stars {
        bottom: 2%;
        max-width: 35%;
      }
    }
  }
}
