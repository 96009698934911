.section--dealers {
  .dealers-carousel {
    .dealers-carousel--slide img {
      display: block;
      max-height: 70px;
      height: auto;
      max-width: 100%;
      margin: auto;
    }
  }
}
