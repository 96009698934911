.section--main-banner {
  background-color: $ts-yellow;
  background-image: url(../images/bg-main-banner.png);
  background-repeat: no-repeat;

  @include mq($from: md) {
    background-position: right center;
    background-size: contain;
  }

  @include mq($until: md) {
    background-position: 30vw center;
    background-size: cover;
  }

  .main-banner--content {
    p {
      font-size: 1.5rem;
      color: $primary-color;
      font-weight: 600;
    }
    small,
    .small {
      font-size: 1.15em;
    }
  }

  .main-banner--cta {
    position: relative;
    padding-bottom: 55px;

    small,
    .small {
      font-weight: 600;
    }

    .btn {
      font-weight: 600;
    }

    img {
      position: absolute;
      left: -1.75rem;
      width: 110px;
      bottom: 0;
    }
  }

  .main-banner--logo {
    img {
      max-width: 140px;
      height: auto;
    }
  }

  .main-banner--product {
    img {
      @include mq($from: xl) {
        max-height: 590px;
      }
      @include mq($from: lg, $until: xl) {
        max-height: 530px;
      }
      @include mq($until: lg) {
        max-height: 470px;
      }
    }
  }
}
