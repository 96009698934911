.modal--register {
  .form-submit-layer {
    &:not(.loading) {
      display: none;
    }

    &.loading {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
  }

  .modal-header {
    &.modal-header--success {
      z-index: 1;
      margin-bottom: -7rem;
    }

    border-bottom: 0px none;

    @include mq($from: lg) {
      padding-bottom: 1rem;
    }

    @include mq($until: lg) {
      padding-bottom: 0;
    }

    .modal-header__logo {
      padding-left: 3.5rem;

      &.last-step {
        height: 0;
        overflow: hidden;
      }
    }

    .btn-close {
      background: none;
      font-size: 3rem;
      line-height: 1em;
      margin: 0.25em auto auto;
      width: 3.5rem;
    }

    img {
      height: 110px;
    }
  }

  .modal-footer {
    border: 0px none;

    .modal-footer__steps {
      &:not(.last-step) {
        button.form-submit {
          display: none;
        }
      }

      button {
        padding: 0.5em 1.5em;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
      }

      a.form-prev-step,
      a.form-next-step {
        color: $primary-color;
        font-size: 3.7rem;
        margin-bottom: -1.5rem;
        display: inline-block;
        position: relative;
        top: 1.2rem;
      }
    }

    .modal-steps-current {
      font-size: 2.5rem;
      opacity: 0.75;
      line-height: 1em;

      > div {
        position: relative;
        top: 0.75em;
      }
    }
  }

  .modal-header__logo {
    flex-grow: 1;
  }

  .modal-content {
    background-color: $ts-yellow;
    background-image: url(../images/bg-modal.png);
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .form-layer-step {
    label {
      font-size: 25px;
      font-weight: 600;
      @extend .font-family--mali;
      color: $primary-color;
    }

    .form-control {
      background-color: transparent;
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-bottom: 2px solid white;
      border-radius: 0;
    }
  }

  a {
    &.form-prev-step,
    &.form-next-step {
      position: relative;
      display: inline-block;
      line-height: 1em;
    }
  }

  .modal-footer__steps {
    &.last-step a.form-next-step,
    &.first-step a.form-prev-step {
      z-index: -10;
      opacity: 0;
    }
    &.last-step button.form-next-step {
      display: none;
    }
  }

  .form-layer-step {
    display: none;

    &.active {
      display: block;
    }

    .radio-form-field {
      position: relative;

      input {
        position: absolute;
        z-index: -1;
      }

      label {
        position: relative;
        background-color: white;
        padding: 4px;
        display: block;
        width: 100%;
        font-weight: 400;
        font-size: 20px;
        cursor: pointer;

        @include mq($until:md) {
          font-size: 16px;
        }

        @include mq($from:md, $until:lg) {
          font-size: 19px;
        }

        @include mq($from:lg) {
          font-size: 22px;
        }

        > span {
          position: relative;
          background-color: white;
          border: 1px solid #c4c4c4;
          width: 1.75em;
          display: inline-block;
          text-align: center;
          font-weight: 600;
        }
      }

      input:checked {
        + label {
          > span {
            background-color: $primary-color;
            border-color: $primary-color;
            color: white;
          }
        }
      }
    }
  }
}

small.danger {
  color: red;
  font-size: 0.9rem;
  font-weight: 600;
}