.header__info-bar {
  position: relative;
  display: block;
  background-color: $primary-color;
  color: white;
  line-height: 1.2em;
  font-size: 1.1rem;
  padding-top: 10px;
  padding-bottom: 10px;

  img {
      width: 1em;
      height: 1em;
      position: relative;
      top: -0.1em;
  }

  .btn-lang {
    background: #fff0f0;
    color: black;
    padding: 5px 10px;
    text-decoration: none;
    font-weight: 600;
    position: absolute;
    right: 25px;
    top: 4px;
  }
}